<template>
    <header class="header">
        <div class="mobile bp-container">
            <a class="logotype" href="https://www.boredpanda.com">BoredPanda</a>
            <a class="login-url" href="javascript:void(0);">Login</a>
            <a class="search" href="javascript:void(0)">
                <svg class="svg-icon icon-search" role="img" height="22" width="22" viewBox="0 0 22 22">
                    <path d="M19.7,21.6L13,15c-1.3,1-3,1.5-4.8,1.5C3.7,16.5,0,12.8,0,8.2
C0,3.7,3.7,0,8.2,0s8.3,3.7,8.3,8.2c0,1.8-0.6,3.4-1.5,4.8l6.6,6.6c0.5,0.5,0.5,1.4,0,1.9C21.1,22.1,20.2,22.1,19.7,21.6z M8.2,2.7
c-3,0-5.5,2.5-5.5,5.5c0,3,2.5,5.5,5.5,5.5c1,0,2-0.3,2.8-0.8c0.8-0.5,1.5-1.1,1.9-1.9c0.5-0.8,0.8-1.8,0.8-2.8
C13.8,5.2,11.3,2.7,8.2,2.7z"></path>
                </svg>
            </a>
            <div class="search-form not-logged-in"><a class="modal-close" href="javascript:void(0)"><i>
                <svg class="svg-icon icon-tooltip-close" role="img" height="12" width="12" viewBox="0 0 12 12">
                    <path d="M10.7,12L6,7.3L1.3,12L0,10.7L4.7,6L0,1.3L1.3,0L6,4.7L10.7,0L12,1.3L7.3,6
l4.7,4.7L10.7,12z"></path>
                </svg>
            </i></a>
                <form method="get" action="https://www.boredpanda.com"><input class="text" type="search" value=""
                                                                              autocomplete="off" placeholder="Search..."
                                                                              name="s">
                    <button class="submit" type="submit">Search</button>
                </form>
            </div>
        </div>
        <div class="responsive-sub-menu clearfix">
            <ul> <!-- <li > <a href=""><?/*=__('Home', 'boredpanda')*/?></a> </li>-->
                <li><a href="https://www.boredpanda.com/art/"><span class="category-name">Art</span></a></li>
                <li><a href="https://www.boredpanda.com/photography/"><span class="category-name">Photography</span></a>
                </li>
                <li><a href="https://www.boredpanda.com/animals/"><span class="category-name">Animals</span></a></li>
                <li><a href="https://www.boredpanda.com/funny/"><span class="category-name">Funny</span></a></li>
                <li><a href="https://www.boredpanda.com/travel/"><span class="category-name">Travel</span></a></li>
                <li><a href="https://www.boredpanda.com/illustration/"><span
                    class="category-name">Illustration</span></a></li>
                <li><a href="https://www.boredpanda.com/comics/"><span class="category-name">Comics</span></a></li>
                <li><a href="https://www.boredpanda.com/diy/"><span class="category-name">DIY</span></a></li>
                <li><a href="https://www.boredpanda.com/parenting/"><span class="category-name">Parenting</span></a>
                </li>
                <li><a href="https://www.boredpanda.com/challenge/"><span class="category-name">Challenge</span></a>
                </li>
                <li><a href="https://www.boredpanda.com/ask-pandas/"><span class="category-name">Ask Pandas</span></a>
                </li>
                <li>
                    <!--<a id="mobile-categories-show" href="javascript: void(0);">More<span class="icon icon-arrow-down-menu"></span></a>-->
                    <a id="mobile-categories-show" href="javascript: void(0);">More<span class="arrow-down-menu"></span></a>
                </li>
            </ul>
        </div>
        <nav class="mobile-more-categories-list" style="display: none;">
            <ul>
                <li><a href="https://www.boredpanda.com/other/"><span class="category-name">Other</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/nature/"><span class="category-name">Nature</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/social-issues/"><span class="category-name">Social Issues</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/people/"><span class="category-name">People</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/fashion/"><span class="category-name">Fashion</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/food/"><span class="category-name">Food</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/body-art-art/"><span class="category-name">Body Art</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/entertainment/"><span class="category-name">Entertainment</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/history/"><span class="category-name">History</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/weird/"><span class="category-name">Weird</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/advertising/"><span
                    class="category-name">Advertising</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/design/"><span class="category-name">Design</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/recycling/"><span class="category-name">Recycling</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/movies-tv/"><span
                    class="category-name">Movies &amp; tv</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/celebrities/"><span
                    class="category-name">Celebrities</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/relationships/"><span class="category-name">Relationships</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/beauty/"><span class="category-name">Beauty</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/creepy/"><span class="category-name">Creepy</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/occasions/"><span class="category-name">Occasions</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/vintage/"><span class="category-name">Vintage</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/climate/"><span class="category-name">Climate</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/recipes/"><span class="category-name">Recipes</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/quotes/"><span class="category-name">Quotes</span><span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/reviews/"><span class="category-name">Reviews</span><span
                    class="categories-arrow-right"></span></a></li>
                <li class="wide-border-top"><a href="https://www.boredpanda.com/contact/">Contact<span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://jobs.boredpanda.com/">Jobs<span class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/advertise/">Advertise<span
                    class="categories-arrow-right"></span></a>
                </li>
                <li><a href="https://www.boredpanda.com/privacy-policy/">Privacy Policy<span
                    class="categories-arrow-right"></span></a></li>
                <li><a href="https://www.boredpanda.com/bored-panda-newsletter/">Newsletter<span
                    class="categories-arrow-right"></span></a></li>
            </ul>
        </nav>
        <div id="header-variation-new" class="desktop bp-container clearfix variation">
            <div class="logotype">
                <a href="https://www.boredpanda.com"><img
                    v-lazy="'https://assets.boredpanda.com/blog/wp-content/themes/boredpanda/images/boredpanda_logo_horizontal.svg'"></a>
            </div> <!-- .logotype -->
            <nav class="nav left top-level-navigation">
                <ul> <!--<li > <a href=""><?/*=__('Home', 'boredpanda')*/?></a> </li>-->
                    <li><a class="" href="https://www.boredpanda.com/art/">Art</a></li>
                    <li><a class="" href="https://www.boredpanda.com/photography/">Photography</a></li>
                    <li><a class="" href="https://www.boredpanda.com/animals/">Animals</a></li>
                    <li><a class="" href="https://www.boredpanda.com/funny/">Funny</a></li>
                    <li class="more"><a href="javascript:void(0)"> More
                        <svg class="svg-icon icon-arrow-down-menu" role="img" height="9" width="16" viewBox="0 0 16 9">
                            <g>
                                <path d="M15.7,1.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0l-6,6
l-6-6c-0.4-0.4-1.2-0.4-1.6,0C0.1,0.5,0,0.8,0,1.1c0,0.3,0.1,0.6,0.3,0.8l6.9,6.7c0.4,0.4,1.2,0.4,1.6,0L15.7,1.9z"></path>
                            </g>
                        </svg>
                    </a>
                        <div class="more-categories">
                            <div class="bp-container">
                                <ul>
                                    <li class="last-visible-category" style="display: none;"><a href="/funny/">Funny</a>
                                    </li>
                                    <li><a href="https://www.boredpanda.com/illustration/">Illustration</a></li>
                                    <li><a href="https://www.boredpanda.com/parenting/">Parenting</a></li>
                                    <li><a href="https://www.boredpanda.com/ask-pandas/">Ask Pandas</a></li>
                                    <li><a href="https://www.boredpanda.com/diy/">DIY</a></li>
                                    <li><a href="https://www.boredpanda.com/comics/">Comics</a></li>
                                    <li><a href="https://www.boredpanda.com/challenge/">Challenge</a></li>
                                    <li><a href="https://www.boredpanda.com/other/">Other</a></li>
                                    <li><a href="https://www.boredpanda.com/travel/">Travel</a></li>
                                    <li><a href="https://www.boredpanda.com/nature/">Nature</a></li>
                                    <li><a href="https://www.boredpanda.com/social-issues/">Social Issues</a></li>
                                    <li><a href="https://www.boredpanda.com/people/">People</a></li>
                                    <li><a href="https://www.boredpanda.com/fashion/">Fashion</a></li>
                                    <li><a href="https://www.boredpanda.com/food/">Food</a></li>
                                    <li><a href="https://www.boredpanda.com/body-art-art/">Body Art</a></li>
                                    <li><a href="https://www.boredpanda.com/entertainment/">Entertainment</a></li>
                                    <li><a href="https://www.boredpanda.com/history/">History</a></li>
                                    <li><a href="https://www.boredpanda.com/weird/">Weird</a></li>
                                    <li><a href="https://www.boredpanda.com/advertising/">Advertising</a></li>
                                    <li><a href="https://www.boredpanda.com/design/">Design</a></li>
                                    <li><a href="https://www.boredpanda.com/recycling/">Recycling</a></li>
                                    <li><a href="https://www.boredpanda.com/movies-tv/">Movies &amp; tv</a></li>
                                    <li><a href="https://www.boredpanda.com/celebrities/">Celebrities</a></li>
                                    <li><a href="https://www.boredpanda.com/relationships/">Relationships</a></li>
                                    <li><a href="https://www.boredpanda.com/beauty/">Beauty</a></li>
                                    <li><a href="https://www.boredpanda.com/creepy/">Creepy</a></li>
                                    <li><a href="https://www.boredpanda.com/occasions/">Occasions</a></li>
                                    <li><a href="https://www.boredpanda.com/vintage/">Vintage</a></li>
                                    <li><a href="https://www.boredpanda.com/climate/">Climate</a></li>
                                    <li><a href="https://www.boredpanda.com/recipes/">Recipes</a></li>
                                    <li><a href="https://www.boredpanda.com/quotes/">Quotes</a></li>
                                    <li><a href="https://www.boredpanda.com/reviews/">Reviews</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
            <nav class="nav right">
                <ul>
                    <li class="search"><a class="search-icon" href="javascript:void(0);"> <i>
                        <svg class="svg-icon icon-search" role="img" height="22" width="22" viewBox="0 0 22 22">
                            <path d="M19.7,21.6L13,15c-1.3,1-3,1.5-4.8,1.5C3.7,16.5,0,12.8,0,8.2
C0,3.7,3.7,0,8.2,0s8.3,3.7,8.3,8.2c0,1.8-0.6,3.4-1.5,4.8l6.6,6.6c0.5,0.5,0.5,1.4,0,1.9C21.1,22.1,20.2,22.1,19.7,21.6z M8.2,2.7
c-3,0-5.5,2.5-5.5,5.5c0,3,2.5,5.5,5.5,5.5c1,0,2-0.3,2.8-0.8c0.8-0.5,1.5-1.1,1.9-1.9c0.5-0.8,0.8-1.8,0.8-2.8
C13.8,5.2,11.3,2.7,8.2,2.7z"></path>
                        </svg>
                    </i> </a>
                        <div class="search-box"><a class="search-close" href="javascript:void(0)"><i>
                            <svg class="svg-icon icon-tooltip-close" role="img" height="12" width="12"
                                 viewBox="0 0 12 12">
                                <path d="M10.7,12L6,7.3L1.3,12L0,10.7L4.7,6L0,1.3L1.3,0L6,4.7L10.7,0L12,1.3L7.3,6
l4.7,4.7L10.7,12z"></path>
                            </svg>
                        </i></a>
                            <form method="get" action="https://www.boredpanda.com">
                                <!-- <input class="submit" type="submit" value="Go"> -->
                                <button class="submit" type="submit"></button>
                                <input class="text" type="search" value="" placeholder="Search..." name="s"></form>
                        </div>
                    </li>
                    <li class="add-post"><a class="add-post-top-button" href="/add-new-post/"><i>
                        <svg class="svg-icon icon-add-post-form-top" role="img" height="14" width="20"
                             viewBox="0 0 20 14">
                            <path d="M15,4c-0.1,0-0.2,0-0.4,0C13.8,1.6,11.6,0,9,0C5.7,0,3,2.7,3,6v0.1c-1.7,0.4-3,2-3,3.9c0,2.2,1.8,4,4,4h5V9.4l-1.3,1.3
C7.5,10.9,7.3,11,7,11s-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l3-3c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.5-0.1,0.8,0
c0.1,0,0.2,0.1,0.3,0.2l3,3c0.4,0.4,0.4,1,0,1.4C13.5,10.9,13.3,11,13,11c-0.3,0-0.5-0.1-0.7-0.3L11,9.4V14h4c2.8,0,5-2.2,5-5
C20,6.2,17.8,4,15,4z"></path>
                        </svg>
                    </i> Add post </a></li>
                    <li><a class="login-url visible-downto-xs" href="javascript:void(0);">Login / Register</a></li>
                </ul>
            </nav>
        </div>
        <div id="categories-variation-new" class="top-categories variation">
            <div class="bp-container">
                <ul>
                    <li class="border-left"><a href="https://www.boredpanda.com" class="popular "> Featured </a></li>
                    <li><a href="https://www.boredpanda.com?show=trending" class="trending"> Trending </a></li>
                    <li><a href="https://www.boredpanda.com?show=recent" class="latest"> Latest </a></li>
                    <li><a class="newsletter" href="https://www.boredpanda.com/bored-panda-newsletter/">Newsletter</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'boredpanda-header',
}
</script>

<style scoped lang="scss">
.header {
    position: relative;
    width: 100%;
    background: #fff;
    z-index: 9998;

    .mobile {
        position: relative;
        z-index: 9999;
        background-color: #363946;
        height: 55px;
        width: 100%;
        max-width: 100%;
        display: none;
        @media(max-width: 979px) {
            display: block;
        }

        .logotype {
            background: url('/images/panda-big-logo-mobile.png') no-repeat left center;
            background-size: 132px 37x;
            display: inline-block;
            position: relative;
            width: 132px;
            height: 37px;
            margin: 9px 0 8px 14px;
            text-indent: -9999px;

            &.logo-category {
                background: url('/images/panda-head-logo-mobile.png') no-repeat left center;
                background-size: 31px 37px;
                display: inline-block;
                position: relative;
                width: 31px;
                height: 37px;
            }

            &.curioso {
                background: url('/images/panda-curioso-big-logo-mobile.png?v=1') no-repeat left center;
                background-size: 150px 37x;
                width: 150px;
            }
        }

        .logo-text {
            position: relative;
            height: 37px;
            color: white;
            font-size: 16px;
            line-height: 55px;
            margin-left: 8px;
            vertical-align: bottom;
        }

        .add-post {
            float: right;
            height: 35px;
            line-height: 35px;
            background: #d74b1f;
            color: #fff;
            font-size: 13px;
            margin-top: 10px;
            padding: 0 13px;
            text-align: center;
            font-weight: 700;
            border-radius: 5px;
            margin-left: 10px;
            margin-right: 10px;

            &:hover {
                opacity: 0.75;
            }

            @media(max-width: 767px) {
                display: none;
            }

            i {
                margin-bottom: 4px;
                margin-right: 10px;
            }
        }

        .login-url {
            float: right;
            margin-right: 1.025em;
            display: block;
            font-size: 13px;
            padding: 0 13px;
            text-align: center;
            color: #fff;
            border: 2px solid #9799a2;
            border-radius: 5px;
            @media(max-width: 979px) {
                margin-top: 10px;
                height: 35px;
                line-height: 31px;
                box-sizing: border-box;
            }
            @media(max-width: 480px) {
                height: 35px;
                line-height: 31px;
            }
        }

        .my-menu {
            float: right;
            margin-top: 13px;
            padding-left: 10px;
            padding-right: 14px;
            height: 40px;
            display: inline-block;
            line-height: 40px;
            @media(max-width: 479px) {
                padding-left: 0;
            }

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: block;
                float: right;
            }

            span {
                display: block;
                float: right;
                line-height: 2.5em;
                margin-left: 0.625em;
                color: #7d7d7d;
                font-weight: normal;
            }

            .triangle-icon {
                display: block;
                float: right;
                line-height: 2.5em;
                margin: 1.1em 0 0 0.3em;
                background: url(/images/arrow-down.png) no-repeat center;
                border: 0;
                background-size: 8px 4px;
                width: 8px;
                height: 4px;
            }

            &.active {
                span {
                    color: #d74b1f;
                }

                .triangle-icon {
                    background: url(/images/expanded-arrow-menu.png) no-repeat;
                }
            }
        }

        .submenu {
            display: none;
            position: absolute;
            top: 55px;
            left: 0;
            width: 100%;
            z-index: 10000;

            ul {
                li {
                    border-bottom: 1px solid #363946;
                    background-color: #313340;
                    height: 43px;
                    line-height: 43px;
                    display: block;
                    float: none;
                    text-align: left;
                    padding-left: 1.025em;
                    width: 100%;
                    box-sizing: border-box;

                    a {
                        color: #9799a2;
                        font-size: 1.5em;
                        font-weight: normal;
                        display: block;
                        height: 100%;

                        span.pandas-count {
                            margin-left: 20px;
                            background: #9799a2;
                            color: #313340;
                            min-width: 15px;
                            line-height: 1;
                            display: inline-block;
                            vertical-align: middle;
                            text-align: center;
                            padding: 3px 3px;
                            border-radius: 3px;
                            font-weight: bold;
                            font-size: 13px;
                        }

                        span.category-name {
                            display: block;
                            float: left;
                            font-weight: 500;
                            font-size: 13px;
                        }

                        span.icon-arrow-right-categories {
                            margin: 0 16px 0 0;
                            display: block;
                            float: right;
                            font-size: 15px;
                            line-height: 42px;
                        }

                        span.categories-arrow-right {
                            margin: 13px 20px 0 0;
                            display: block;
                            float: right;
                            background: url(/images/arrow-right.png) no-repeat center;
                            background-size: 9px 13x;
                            width: 9px;
                            height: 13px;
                            @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background: url(/images/arrow-right@2x.png) no-repeat center;
                                background-size: 9px 13px;
                            }
                            @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
                                background: url(/images/arrow-right@3x.png) no-repeat center;
                                background-size: 9px 13px;
                            }
                        }
                    }
                }
            }
        }

        .search {
            float: right;
            margin-right: 12px;
            height: 22px;
            display: inline-block;
            line-height: 22px;
            vertical-align: middle;
            color: #a3a3a3;
            margin-top: 14px;
        }

        .search-form {
            display: none;
            width: 300px;
            padding: 15px;
            background: #fff;
            border-radius: 5px;
            box-sizing: border-box;
            position: absolute;
            right: 10px;

            .modal-close {
                position: absolute;
                left: 26px;
                top: 29px;
                color: #989898;
            }

            input {
                border: 1px solid #d9d9d9;
                background: white;
                font-size: 16px;
                color: #989898;
                border-radius: 5px;
                outline: none;
                width: 197px;
                box-sizing: border-box;
                padding: 0 5px 0 30px;
                height: 40px;
                display: inline-block;
            }

            .submit {
                -webkit-appearance: none;
                cursor: pointer;
                background-color: #56b863;
                color: #ffffff;
                font-size: 14px;
                font-weight: bold;
                height: 40px;
                display: inline-block;
                border: 0;
                border-radius: 5px;
                padding: 0 9px;
                vertical-align: middle;
                outline: none;
            }

            &:after {
                z-index: 1;
                content: "";
                position: absolute;
                top: -9px;
                left: 186px;
                border-top: 0 solid transparent;
                border-bottom: 12px solid white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                @media (min-width: 768px) {
                    left: 48px;
                }
            }

            &.not-logged-in:after {
                left: 200px;
                @media (min-width: 768px) {
                    left: 72px;
                }
            }
        }

        .notification-icon {
            float: right;
            display: block;
            color: #a3a3a3;
            height: 55px;
            line-height: 55px;
            margin: 0 25px 0 0;
            position: relative;

            .svg-icon {
                vertical-align: middle;
            }

            .notification-count {
                position: absolute;
                top: 14px;
                left: 23px;
                display: block;
                background: #9799a2;
                width: 21px;
                height: 21px;
                border-radius: 50%;
                line-height: 21px;
                text-align: center;
                font-size: 11px;
                color: #fff;
                margin-left: -12px;
                margin-top: -5px;
                padding: 0;
                border: 2px solid #363946;

                &.unseen {
                    background: #d74b1f;
                }
            }
        }

        .dropdown-menu.notifications-drop-down {
            right: 13px;
            left: initial;
            top: 55px;
            width: 300px;

            &:before {
                display: none;
            }

            &:after {
                left: 221px;
                top: -14px;
                @media (min-width: 480px) {
                    left: 212px;
                }
                @media (min-width: 768px) {
                    left: 83px;
                }
            }

            ul {
                max-height: 300px;

                a {
                    border: none;

                    .text {
                        .notification-text {
                            font-size: 12px;
                            max-width: 166px;
                        }
                    }
                }
            }
        }
    }

    .desktop {
        display: block;
        @media(max-width: 979px) {
            display: none;
        }

        .logotype {
            position: relative;
            margin-right: 15px;
            display: inline-block;
            vertical-align: middle;

            a {
                display: block;

                img {
                    width: 149px;
                }
            }
        }
    }

    .responsive-sub-menu {
        &::-webkit-scrollbar {
            display: none;
        }

        display: none;
        background: #313340;
        width: 100%;
        height: auto;
        top: 0;
        z-index: 9998;
        position: relative;
        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        ul {
            z-index: 10000;
            display: table;
            float: left;
            padding: 0;
            width: 100%;
            margin: 0;

            li {
                display: table-cell;
                list-style: none;
                width: auto;
                white-space: nowrap;
                position: relative;
                height: 47px;
                text-align: center;
                vertical-align: middle;
                padding: 0 14px 0 14px;

                &.active {
                    border-bottom: 2px solid #fff;

                    a {
                        color: #fff;
                        line-height: 25px;
                    }
                }

                &.disabled-active {
                    background-color: #353535;
                }

                a {
                    display: table;
                    vertical-align: middle;
                    color: #9799a2;
                    text-decoration: none;
                    font-weight: 500;
                    width: 100%;
                    font-family: Roboto, sans-serif;
                    line-height: 26px;
                    font-size: 13px;

                    &#mobile-categories-show {
                        display: inline-block;

                        .icon-arrow-down-menu {
                            font-size: 5px;
                            margin-left: 5px;
                        }

                        .arrow-down-menu {
                            background: url(/images/arrow-down-mobile.png) no-repeat center;
                            background-size: 8px 5px;
                            width: 8px;
                            height: 5px;
                            margin-left: 5px;
                            margin-bottom: 2px;
                            display: inline-block;
                            @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background: url(/images/arrow-down-mobile@2x.png) no-repeat center;
                                background-size: 8px 5px;
                            }
                            @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
                                background: url(/images/arrow-down-mobile@3x.png) no-repeat center;
                                background-size: 8px 5px;
                            }
                        }
                    }

                    &:hover {
                        color: #fff;
                    }

                    &#mobile-categories-show:hover {
                        color: #9799a2;
                    }
                }
            }

            .activesubmenu a {
                border-bottom: 6px solid #d74b1f;
            }
        }

        @media(max-width: 979px) {
            display: block;
        }
    }

    .nav {
        display: inline-block;
        height: 60px;
        vertical-align: middle;

        &.right {
            width: 355px;

            li {
                display: inline-block;
            }
        }

        &.left {
            width: calc(100% - 520px);

            ul {
                li {
                    display: inline-block;
                    vertical-align: middle;

                    &.more {
                        .icon-arrow-down-menu {
                            width: 8px;
                            vertical-align: middle;
                            margin-top: -2px;
                            color: inherit;
                        }

                        .arrow-down-menu {
                            background: url(/images/arrow-down-desktop.png) no-repeat center;
                            background-size: 8px 5px;
                            width: 8px;
                            height: 5px;
                            margin-left: 3px;
                            margin-bottom: 3px;
                            display: inline-block;
                            @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background: url(/images/arrow-down-desktop@2x.png) no-repeat center;
                                background-size: 8px 5px;
                            }
                            @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
                                background: url(/images/arrow-down-desktop@3x.png) no-repeat center;
                                background-size: 8px 5px;
                            }
                        }

                        .more-categories {
                            background: #353535;
                            width: 100%;
                            display: none;
                            position: absolute;
                            right: 0;
                            z-index: 10;
                            top: 60px;

                            ul {
                                width: 100%;
                                margin: 0 auto;

                                li {
                                    // width: 100%;
                                    display: inline-block;
                                    color: #555454;

                                    &:not(:last-child):after {
                                        content: "•";
                                        width: 10px;
                                        height: 10px;
                                        font-size: 20px;
                                        top: 2px;
                                        position: relative;
                                    }
                                }
                            }

                            a {
                                display: inline-block;
                                font-size: 14px;
                                color: #bfbfbf;
                                text-decoration: none;
                                font-weight: 500;
                                margin: 0;
                                padding: 14px 14px 8px 14px;

                                &:hover {
                                    background-color: #353535;
                                    color: white;
                                }
                            }
                        }
                    }

                    a {
                        border: none;
                        color: #353535;
                        font-weight: 500;
                        margin: 0 16px;

                        &:hover {
                            background-color: #fff;
                            color: #d74b1f;
                            padding: 0 0 8px 0;
                        }

                        &.es-version, &.de-version {
                            margin: 24px 11px 10px 11px;
                        }
                    }

                    &.active a {
                        background-color: #fff;
                        color: #d74b1f;
                        padding: 0 0 7px 0;
                        border-bottom: 2px solid #d74b1f;
                    }
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                height: 60px;
                line-height: 60px;

                div.dropdown-menu {
                    ul {
                        li {
                            height: auto;
                        }
                    }
                }

                a {
                    border: none;

                    &.unseen {
                        border-bottom: 2px solid #fff;
                    }

                    &.login-url {
                        display: block;
                        border: 2px solid #bfbfbf;
                        padding: 0.65em;
                        height: 15px;
                        text-align: center;
                        margin: 10px 0 15px 0;
                        border-radius: 5px;
                        text-transform: none;
                        font-weight: 500;
                        color: #7d7d7d;
                        line-height: 17px;
                        width: 130px;

                        &:hover {
                            border: 2px solid #929292;
                            background-color: #929292;
                            color: #fff;
                        }
                    }
                }

                &.search {
                    .search-icon {
                        padding: 0 8px;
                        display: inline-block;
                        color: #a3a3a3;

                        &:hover {
                            color: #353535;
                        }

                        .svg-icon {
                            vertical-align: middle;
                        }
                    }

                    .search-box {
                        display: none;
                        top: 11px;
                        width: 230px;

                        .search-close {
                            position: absolute;
                            background: none;
                            padding: 0;
                            left: 15px;
                            top: 0;
                            font-size: 10px;
                            height: 38px;
                            width: 12px;
                            display: block;
                            line-height: 38px;
                            color: #7d7d7d;
                        }

                        input {
                            width: 150px;
                            background-color: #f0f0f0;
                            border: 1px solid #cccccc;
                            border-bottom-left-radius: 5px;
                            border-top-left-radius: 5px;
                            border-right: none;
                            padding: 0.425em;
                            height: 25px;
                            padding-left: 35px;
                        }

                        button {
                            background-color: #f0f0f0;
                            background-image: url(/images/icon-search-variation.png);
                            background-repeat: no-repeat;
                            background-position: center center;
                            border: 1px solid #cccccc;
                            border-bottom-right-radius: 5px;
                            border-top-right-radius: 5px;
                            border-left: none;
                            width: 25px;
                            padding: 0.425em;
                            height: 25px;
                        }
                    }
                }

                &.notifications {
                    margin: 0 1.3em 0 1.3em;

                    .icon-bell {
                        background-size: 22px 26px !important;
                        padding: 0 0 0 22px;
                        height: 26px;
                        background: url(/images/notification-bell.png) no-repeat;
                        margin-top: 1em;

                        .notification-count {
                            position: absolute;
                            top: 14px;
                            right: -10px;
                            width: 19px;
                            height: 19px;
                            line-height: 19px;
                            font-size: 0.7em;
                            font-weight: normal;
                            border: 1px solid white;
                        }

                        &:hover {
                            background: url(/images/notification-bell-hover.png) no-repeat;
                        }
                    }

                    &.open {
                        .icon-bell {
                            background: url(/images/notification-bell-hover.png) no-repeat;
                        }
                    }
                }

                &.user-menu {
                    a {
                        img {
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            margin-top: 15px;
                        }

                        span {
                            color: #7d7d7d;
                            text-transform: none;
                            font-weight: normal;
                            margin: 0 0.25em 0 0.5em;
                            font-size: 0.9em;

                            &.pandas-count {
                                display: inline-block;
                                float: none;
                                margin-left: 15px;
                                background: #ccc;
                                color: #353535 !important;
                                padding: 3px 3px;
                                font-size: 12px;
                                min-width: 15px;
                                text-align: center;
                                border-radius: 3px;
                                font-weight: bold;
                            }
                        }

                        .icon-arrow-down-menu {
                            width: 8px;
                            vertical-align: middle;
                            margin-top: -3px;
                            color: #7d7d7d;
                        }

                        .arrow-down-menu {
                            background: url(/images/arrow-down-mobile.png) no-repeat center;
                            background-size: 8px 5px;
                            width: 8px;
                            height: 5px;
                            margin-left: 0px;
                            margin-top: 27px;
                            display: inline-block;
                            @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background: url(/images/arrow-down-mobile@2x.png) no-repeat center;
                                background-size: 8px 5px;
                            }
                            @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
                                background: url(/images/arrow-down-mobile@3x.png) no-repeat center;
                                background-size: 8px 5px;
                            }
                        }

                        &:hover {
                            background-color: #ffffff;

                            span, svg {
                                color: #d74b1f;
                            }
                        }
                    }

                    nav.submenu {
                        left: -190px;
                        top: 3.73em;
                    }
                }

                &.search {
                    a {
                        background: url(/images/icon-search-variation.png) no-repeat center center;
                    }
                }

                &.add-post {
                    display: inline-block;

                    .add-post-top-button {
                        background: #fff;
                        border: 2px solid #bfbfbf;
                        color: #7d7d7d;
                        line-height: 38px;
                        height: 40px;
                        box-sizing: border-box;
                    }

                    a {
                        margin: 10px 20px 10px 10px;
                        border: none;
                        border-radius: 5px;
                        color: #ffffff;
                        padding: 0 17px 0 15px;
                        height: 38px;
                        line-height: 42px;
                        text-transform: none;
                        font-weight: 500;
                        width: auto;
                        border-bottom: 2px solid #b33d19;
                        background: #d74b1f;
                        display: block;

                        &:hover {
                            color: #ffffff;
                            background-color: #f26235;
                            border-color: #d6542b;
                        }
                    }

                    i {
                        background-size: 0;
                        font-size: 14px;
                        line-height: 38px;
                        width: 20px;
                        height: 40px;
                        margin-right: 7px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .top-categories {
        @media (max-width: 979px) {
            display: none;
        }
        height: 40px;
        background: #353535;

        .bp-container {
            overflow: hidden;
        }

        &.variation {
            height: 2.5em;

            ul {
                width: 100%;
                padding: 0;
                margin: 0;

                li {
                    background: #353535;
                    color: #555454;
                    margin-right: 14px;
                    list-style-type: disc;
                    display: block;
                    float: left;

                    a {
                        color: #bfbfbf;
                        font-weight: 500;
                        padding-right: 14px;
                        font-size: 0.875em;
                        display: inline-block;
                        text-decoration: none;
                        line-height: 40px;

                        &:hover {
                            color: #ffffff;
                            border: none;
                        }

                        &.active {
                            color: #fff;
                        }
                    }

                    &:not(:last-child):after {
                        content: "•";
                        width: 10px;
                        height: 10px;
                        font-size: 20px;
                        top: 2px;
                        position: relative;
                    }

                    &.active {
                        a {
                            background: #353535;
                            color: #ffffff;
                            border: none;
                        }
                    }

                    &:hover {
                        background: #353535;
                    }
                }
            }
        }
    }
}
</style>
